import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ipe from "../../assets/projects/onlineipe-01.jpg";
import winex5 from "../../assets/projects/winex5.jpg";
import red from "../../assets/projects/red.jpg";
import restaurant from "../../assets/projects/restaurant.jpg";
import energyeff from "../../assets/projects/energyeff.jpg";
import robot from "../../assets/projects/robot.jpeg";
import sdp from "../../assets/projects/sdp.jpg";
import quimico from "../../assets/projects/quimico.jpg";
import security from "../../assets/projects/security.jpg";
import microfactoria from "../../assets/projects/microfactoria.jpg";
import LeftPROJECTSBackgroundSpots from "../../assets/LeftPROJECTSBackgroundSpots.png";
import ProjectDetailModal from "./ProjectDetail";
import { LeftPROJECTSBackgroundSpot } from "../Sections/memoized-svgs";
import Hidden from "@material-ui/core/Hidden";
import useMenuOnScroll from "../../hooks/useMenuOnScroll";

//Este array fue declarado con el proposito de crear deuda tecnica de mi para ti programador
const plchldrarr = [
  ipe,
  quimico,
  robot,
  energyeff,
  winex5,
  security,
  restaurant,
  sdp,
  microfactoria,
  red,
];

function Projects({ handleCurrentSection }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("ALL");
  const ref = useRef(null);
  useMenuOnScroll(handleCurrentSection, ref.current);

  const handleTabSelection = (tab) => {
    setActiveTab(tab);
    //FILTER PROJECTS BY TYPE
  };

  return (
    <div ref={ref} style={{ position: "relative" }} name="PROJECTS">
      {/* <Hidden smDown> */}
        <img
          src={LeftPROJECTSBackgroundSpots}
          style={{
            zIndex: "-9998",
            position: "absolute",
            top: "-24vw",
            left: "-16vw",
            width: "60vw",
          }}
        />
      {/* </Hidden> */}
      <div>
        <h1> {t("Projects.1")} </h1>
        {/* <img alt="" style={{position:"absolute",width:"60%",height:"100%",left:"0vw",top:"190vh",zIndex:1,}} src={ProjectsLeftBg}></img> */}
        <div className="TabsGroup">
          <button
            className={`BtnTab ${activeTab === "ALL" ? " active" : ""}`}
            onClick={() => handleTabSelection("ALL")}
          >
            {t("Projects.2")}
          </button>
          <button
            className={`BtnTab ${activeTab === "SOFTWARE" ? " active" : ""}`}
            onClick={() => handleTabSelection("SOFTWARE")}
          >
            {t("Projects.3")}
          </button>
          <button
            className={`BtnTab ${activeTab === "IOT" ? " active" : ""}`}
            onClick={() => handleTabSelection("IOT")}
          >
            {t("Projects.4")}
          </button>
          <button
            className={`BtnTab ${activeTab === "HARDWARE" ? " active" : ""}`}
            onClick={() => handleTabSelection("HARDWARE")}
          >
            {t("Projects.5")}
          </button>
          <button
            className={`BtnTab ${
              activeTab === "INSTALLATIONS" ? " active" : ""
            }`}
            onClick={() => handleTabSelection("INSTALLATIONS")}
          >
            {t("Projects.6")}
          </button>
          <button
            className={`BtnTab ${activeTab === "OTHERS" ? " active" : ""}`}
            onClick={() => handleTabSelection("OTHERS")}
          >
            {t("Projects.7")}
          </button>
        </div>
        <div className="ProjectsListContainer">
          {t("Projects.projectsList", { returnObjects: true }).map((p, i) => {
            // console.log("p: ")
            // console.log(p)
            return p.type === activeTab || activeTab === "ALL" ? (              
              <ProjectCard
                key={i}
                imgSource={plchldrarr[i]}
                title={p.title}
                type={p.type}
                subtitle={p.subtitle}
                contentText={p.contentText}
                contentText1={p.contentText1}
                contentText2={p.contentText2}
              ></ProjectCard>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
}

function ProjectCard({
  imgSource,
  title,
  type,
  subtitle,
  contentText,
  contentText1,
  contentText2,
  link,
}) {
  const [showHoverView, setShowHoverView] = useState(false);
  const [open, setOpen] = useState(false);
  const hoverViewDisplay = useRef();

  const handleMouseOverProject = () => {
    setShowHoverView(!showHoverView);
    hoverViewDisplay.current.style.display = !showHoverView ? "flex" : "none";
  };

  const handleModalOpenClose = () => {
    setOpen(!open);
  };

  return (
    <a
      className="ImgCard"
      style={{ backgroundImage: 'url("' + imgSource + '")' }}
      onClick={handleModalOpenClose}
    >
      <Hidden smDown>
        <div
          style={{ height: "100%" }}
          onMouseOver={handleMouseOverProject}
          onMouseOut={handleMouseOverProject}
        >
          <div className="HoverView" ref={hoverViewDisplay}>
            <div>
              <h4 className="notranslate">{title}</h4>
              Ver más
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className="ImgCardLabel">
          <h4 className="notranslate">{title}</h4>
        </div>
      </Hidden>
      <ProjectDetailModal
        open={open}
        handleModalOpenClose={handleModalOpenClose}
        projectInfo={{
          imgSource,
          title,
          type,
          subtitle,
          contentText,
          contentText1,
          contentText2,
        }}
      />
    </a>
  );
}
export default Projects;
