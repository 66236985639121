import React, { useState, useRef } from "react";
import axios from "axios";
import "./App.css";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import useMenuOnScroll from "../../hooks/useMenuOnScroll";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  NameLastNameFieldGroup: {
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      width: "276px",
      display: "inline-block",
    },
  },
  PhoneFieldGroup: {
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      width: "276px",
      display: "inline-block",
      float: "right",
    },
  },
  ContactForm: {
    margin: "0 auto 76px",
    [theme.breakpoints.up("xs")]: {
      width: "",
    },
    [theme.breakpoints.up("md")]: {
      width: "572px",
    },
  },
}));

function Contact({ handleCurrentSection }) {
  const [formSent, setFormSent] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const { t } = useTranslation();
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const ref = useRef(null);
  useMenuOnScroll(handleCurrentSection, ref.current);

  const toggleHover = () => {
    setHover(!hover);
  };

  const callSendEmailApi = async (formData) => {
    const { name, phone, email, description } = formData;
    await axios.post(
      "https://dx4u6bzv8b.execute-api.us-west-2.amazonaws.com/Prod/contact",
      { name, phone, email, description }
    );
    setFormSent(true);
    setName("");
    setPhone("");
    setEmail("");
    setDescription("");
  };

  return (
    <div ref={ref} name={"CONTACT"}>
      <div>
        <h1>{t("Contact.1")}</h1>
        <div style={{ margin: "40px auto 60px" }}>
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              width: "fit-content",
              lineHeight: "32px",
              fontFamily: "Muli-Regular",
              margin: "0 auto",
            }}
          >
            {" "}
            {t("Contact.2")}{" "}
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              width: "fit-content",
              lineHeight: "32px",
              fontFamily: "Muli-Regular",
              margin: "0 auto",
            }}
          >
            {" "}
            {t("Contact.3")}{" "}
          </p>
        </div>
        <div className={classes.ContactForm}>
          <div className={classes.NameLastNameFieldGroup}>
            <label> {t("Contact.4")}</label>
            <TextField
              onChange={(e) => setName(e.target.value)}
              value={name}
              id="outlined-basic"
              name="NameLastNameField"
              variant="outlined"
              style={{ margin: "12px 0 24px" }}
              margin="dense"
              fullWidth
              placeholder={t("Contact.fullNamePlaceholder")}
            />
          </div>

          <div className={classes.PhoneFieldGroup}>
            <label> {t("Contact.5")} </label>
            <TextField
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              id="outlined-basic"
              name="PhoneField"
              variant="outlined"
              style={{ margin: "12px 0 24px" }}
              margin="dense"
              fullWidth
              placeholder={t("Contact.phonePlaceholder")}
            />
          </div>

          <div>
            <label> {t("Contact.6")} </label>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              id="outlined-basic"
              name="EmailField"
              variant="outlined"
              style={{ margin: "12px 0 24px" }}
              margin="dense"
              fullWidth
              placeholder={t("Contact.emailPlaceholder")}
            />
          </div>

          <div>
            <label> {t("Contact.7")} </label>
            <TextField
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              id="outlined-basic"
              name="IdeaField"
              variant="outlined"
              style={{ margin: "12px 0 0", height: "176px" }}
              margin="dense"
              fullWidth
              placeholder={t("Contact.ideaPlaceholder")}
              multiline
              rows={8}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <button
              disabled={!name || !phone || !email || !description}
              onClick={() =>
                callSendEmailApi({ name, phone, email, description })
              }
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
              className="BtnLabor"
              style={{ margin: "32px 0 0 auto" }}
            >
              {t("Contact.8")}
            </button>
            {formSent && (
              <div style={{ textAlign: "center" }}>
                Formulario enviado correctamente.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
