import React from "react";
import { Link } from "react-scroll";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  LaborFooterMemo,
  InstagramLogoMemo,
  FaceBookLogoMemo,
  LinkedInLogoMemo,
} from "./memoized-svgs";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

//Styles by breakpoint
const useStyles = makeStyles((theme) => ({
  IconsList: {
    "& svg": { margin: "8px 16px 0 0" },
  },
  FooterListsAlignment: {
    [theme.breakpoints.down("sm")]: {
      width: "160px",
      margin: "80px auto 0",
    },
  },
  FooterLogoAlignment: {
    margin: "64px 0 0",
    display: "inline-block",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      // display:"block"
    },
  },
  FooterLaborAlignment: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      display: "inline-block",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
      display: "inline-block",
    },
  },
}));

function Footer() {
  const { t } = useTranslation();
  const classes = useStyles();

  const year = new Date();

  return (
    <footer className="AppPadding">
      <div className={classes.FooterLogoAlignment}>
        <LaborFooterMemo></LaborFooterMemo>
      </div>
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <Box mt={7.5} className={classes.FooterListsAlignment}>
            <h2>{t("Footer.Services.title")}</h2>
            <ul>
              <li>
                <a href="#">{t("Footer.Services.1")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Services.2")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Services.3")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Services.4")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Services.5")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Services.6")}</a>
              </li>
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box mt={7.5} className={classes.FooterListsAlignment}>
            <h2>{t("Footer.Projects.title")}</h2>
            <ul>
              <li>
                <a href="#">{t("Footer.Projects.1")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.2")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.3")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.4")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.5")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.6")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.7")}</a>
              </li>
              <li>
                <a href="#" className="notranslate">{t("Footer.Projects.8")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.9")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Projects.10")}</a>
              </li>
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box mt={7.5} className={classes.FooterListsAlignment}>
            <h2>{t("Footer.Company.title")}</h2>
            <ul>
              <li>
                <a href="#">{t("Footer.Company.1")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Company.2")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Company.3")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Company.4")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Company.5")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Company.6")}</a>
              </li>
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box mt={7.5} className={classes.FooterListsAlignment}>
            <h2>{t("Footer.Contact.title")}</h2>
            <ul>
              <li>
                <a href="#">{t("Footer.Contact.email")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Contact.phone")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Contact.company")}</a>
              </li>
              <li>
                <a href="#">{t("Footer.Contact.location")}</a>
              </li>
              <li className={classes.IconsList}>
                <a href={t("Footer.Contact.instagram")}>
                  <InstagramIcon />
                </a>
                <a href={t("Footer.Contact.facebook")}>
                  <FacebookIcon />
                </a>
                <a href={t("Footer.Contact.linkedin")}>
                  <LinkedInIcon />
                </a>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>

      <p
        style={{
          fontSize: 12,
          margin: "60px 0 40px",
          width: "100%",
          display: "inline-block",
        }}
      >
        <span
          className={classes.FooterLaborAlignment}
          style={{ lineHeight: "12px" }}
        >
          <span className={classes.FooterAlignment} style={{ opacity: 0.55 }}>
            © {year.getFullYear()} {t("Footer.Contact.copyrightText")}
          </span>
          <span
            className={classes.FooterAlignment}
            style={{ margin: "0 0 12px 8px" }}
          >
            <Link
              to="main-section"
              spy={true}
              smooth={true}
              duration={500}
              className={"some-class"}
              activeClass="some-active-class"
              style={{ cursor: "pointer" }}
            >
              {t("Footer.Contact.laborDescription")}
            </Link>
          </span>
        </span>
      </p>
    </footer>
  );
}

// const footerList = (list) => {
//   return(
//     <div>

//     </div>
//   )
// }

export default Footer;
