import React from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { LaborLogoNavBar } from "./memoized-svgs/index";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import "./NavBar.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Menu: {
    "& .MuiListItem-gutters": {
      padding: "0",
    },
  },
}));

function Navbar({ sticky, currentScrollSection }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [activeSection, setActiveSection] = useState('HOME');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
  }

  function onSelectFlag(countryCode) {
    if (countryCode === "UY") {
      handleLanguageChange("es");
    } else {
      handleLanguageChange("en");
    }
  }

  // const handleSectionSelection = (section) => {
  //   setActiveSection(section);
  // }

  return (
    <nav
      className={sticky ? "navbar navbar-sticky" : "navbar"}
      style={sticky ? { padding: "0 10vw", zIndex: 10 } : { zIndex: 10 }}
    >
      <div>
        <Link to="main-section">
          <LaborLogoNavBar style={{ height: "10vh" }}></LaborLogoNavBar>
        </Link>
      </div>
      <div className={"MenuLinks"}>
        <Hidden mdUp>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ float: "right", color: "#EC4847" }}
          >
            {" "}
            <MenuIcon />{" "}
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            style={{ padding: "0" }}
            className={classes.Menu}
            PaperProps={{ style: { maxHeight: 68 * 4.5, width: "20ch" } }}
          >
            <MenuItem key={"Home"} selected={false} onClick={handleClose}>
              <Link
                to="main-section"
                spy={true}
                smooth={true}
                duration={500}
                className={"some-class"}
                activeClass="some-active-class"
                style={{ width: "100%", padding: "12px" }}
              >
                {t("Home.1")}
              </Link>
            </MenuItem>

            <MenuItem key={"Services"} selected={false} onClick={handleClose}>
              <Link
                to="services-section"
                spy={true}
                smooth={true}
                duration={500}
                className="some-class"
                activeClass="some-active-class"
                style={{ width: "100%", padding: "12px" }}
              >
                {t("Services.title")}
              </Link>
            </MenuItem>
            <MenuItem key={"Projects"} selected={false} onClick={handleClose}>
              <Link
                to="projects-section"
                spy={true}
                smooth={true}
                duration={500}
                className="some-class"
                style={{
                  width: "100%",
                  padding: "12px",
                }}
                activeClass="some-active-class"
              >
                {t("Projects.1")}
              </Link>
            </MenuItem>
            <MenuItem key={"Company"} selected={false} onClick={handleClose}>
              <Link
                to="company-section"
                spy={true}
                smooth={true}
                duration={500}
                className="some-class"
                activeClass="some-active-class"
                style={{ width: "100%", padding: "12px" }}
              >
                {t("Company.title")}
              </Link>
            </MenuItem>
          </Menu>
        </Hidden>

        <Hidden smDown>
          <Link
            to="main-section"
            spy={true}
            smooth={true}
            duration={500}
            className={`${currentScrollSection === "HOME" ? " active" : ""}`}
            activeClass="some-active-class"
            // onClick={()=>handleSectionSelection('HOME')}
          >
            {t("Home.1")}
          </Link>

          <Link
            to="services-section"
            spy={true}
            smooth={true}
            duration={500}
            className={`${
              currentScrollSection === "SERVICES" ? " active" : ""
            }`}
            // onClick={()=>handleSectionSelection('SERVICES')}
            activeClass="some-active-class"
          >
            {t("Services.title")}
          </Link>

          <Link
            to="projects-section"
            spy={true}
            smooth={true}
            duration={500}
            className={`${
              currentScrollSection === "PROJECTS" ? " active" : ""
            }`}
            // onClick={()=>handleSectionSelection('PROJECTS')}
            activeClass="some-active-class"
          >
            {t("Projects.1")}
          </Link>

          <Link
            to="company-section"
            spy={true}
            smooth={true}
            duration={500}
            className={`${currentScrollSection === "COMPANY" ? " active" : ""}`}
            // onClick={()=>handleSectionSelection('COMPANY')}
            activeClass="some-active-class"
          >
            {t("Company.title")}
          </Link>
        </Hidden>
        <div style={{ marginTop: "-2px" }}>
          <ReactFlagsSelect
            style={{ outline: "none" }}
            countries={["US", "UY"]}
            customLabels={{
              US: "EN-US",
              UY: "ES-UY",
            }}
            showSelectedLabel={false}
            onSelect={onSelectFlag}
            placeholder="Select Language"
            selectedSize={14}
            optionsSize={14}
            defaultCountry="US"
            className="LanguageSelect"
          />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
