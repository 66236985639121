import React, { useRef } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@material-ui/core";
import useMenuOnScroll from "../../hooks/useMenuOnScroll";
import ServicesRightBgForSD from "../../assets/ServicesRightBackgroundSpots.png";
import Hidden from "@material-ui/core/Hidden";

import {
  DevicesMemo,
  HardwareMemo,
  iotMemo,
  mobileMemo,
  ProductDiscMemo,
  industryMemo,
} from "./memoized-svgs";

const servplchldr = [
  iotMemo,
  mobileMemo,
  ProductDiscMemo,
  industryMemo,
  HardwareMemo,
  DevicesMemo,
];

function Services({ handleCurrentSection }) {
  const { t } = useTranslation();

  const ref = useRef(null);
  useMenuOnScroll(handleCurrentSection, ref.current);

  return (
    <div ref={ref} name="SERVICES">
      <Hidden smUp>
        <img
          alt=""
          style={{
            position: "absolute",
            width: "60vw",
            height: "32vh",
            right: "0vw",
            top: "120vh",
            zIndex: -1,
          }}
          src={ServicesRightBgForSD}
        ></img>
      </Hidden>
      <h1>{t("Services.title").toLocaleUpperCase()}</h1>
      <div>
        <Grid container spacing={1}>
          {t("Services.servicesList", { returnObjects: true }).map((s, i) => {
            return (
              <Grid key={(s, i)} item xs={12} sm={6} md={4}>
                <Box mt={5} style={{ textAlign: "center" }}>
                  <ServiceCard
                    SvgComponent={servplchldr[i]}
                    title={s.title}
                    description={s.description}
                  ></ServiceCard>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}

function ServiceCard({ SvgComponent, title, description }) {
  return (
    <div
      className="floating"
      style={{
        maxWidth: /*"320px"*/ "220px",
        minHeight: "344px",
        backgroundColor: "#fff",
        border: "solid 1px #ddd",
        padding: "20px 34px",
        borderRadius: "4px",
        display: "inline-block",
        textAlign: "left",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "24px" }}>
        <SvgComponent></SvgComponent>
      </div>

      <h2
        style={{
          fontFamily: "Muli-Bold",
          fontSize: 16,
          display: "block",
          marginBottom: "24px",
        }}
      >
        {title}
      </h2>
      <p
        style={{
          fontFamily: "Muli-Regular",
          fontSize: 13,
          display: "block",
          lineHeight: "24px",
        }}
      >
        {description}
      </p>
    </div>
  );
}

export default Services;
