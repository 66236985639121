import React, { useRef } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
// import CompanyPlaceholder from "../../assets/company/CompanyPlaceholder.png";
import {
  FingLogoMemo,
  IpeLogoMemo,
  ShillBidderMemo,
  PalacioHotelMemo,
  UnknownPlaceholderMemo,
  CompanyGirl,
} from "./memoized-svgs";
import CompanyRightBgForSD from "../../assets/CompanyRightBackgroundSpots.png";
import { Grid, Box } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import useMenuOnScroll from "../../hooks/useMenuOnScroll";

function Company({ handleCurrentSection }) {
  const { t } = useTranslation();
  const ref = useRef(null);
  useMenuOnScroll(handleCurrentSection, ref.current);

  return (
    <div ref={ref} name="COMPANY" style={{ minHeight: "600px", position:'relative' }}>
      <h1>{t("Company.title")}</h1>
      <div>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box mt={10} mx={1}>
              <h2>{t("Company.subtitle")}</h2>
              <p>{t("Company.text1")}</p>
            </Box>
            <Box mx={1} mt={3}>
              <p>{t("Company.text2")}</p>
            </Box>
            <Box mx={1} mt={3}>
              <p>{t("Company.text3")}</p>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={6} style={{ position: "relative" }}>
              <CompanyGirl
                style={{
                  zIndex: "-9999",
                  position: "absolute",
                  maxWidth: "128%",
                  top: "-12vw",
                  right: "-16vw",
                }}
              ></CompanyGirl>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <img
              src={CompanyRightBgForSD}
              style={{
                zIndex: "-9999",
                position: "absolute",
                right: "-20vw",
                top: "-20vh",
                width: "60vw",
              }}
            ></img>
          </Hidden>
        </Grid>
      </div>
    </div>
  );
}

export default Company;
