import React, { useState } from "react";
import {
  LeftBackgroundMemo,
  GuyGazingMemo,
  GuyGazing2Memo,
} from "./memoized-svgs";
import "./App.css";
import LeftBackground from "../../assets/LeftBackground.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import useMenuOnScroll from "../../hooks/useMenuOnScroll";

const useStyles = makeStyles((theme) => ({
  MainContentAlignment: {
    height: "100vh",
    display: "flex",
    height: "fit-content",
    [theme.breakpoints.up("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
    },
  },
  MainCoverLeft: {
    zIndex: 2,
    marginTop: "26vh",
    width: "68vw",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      textAlign: "center",
      marginTop: "20vh",
    },
    "& p": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign:'center',
      },
    },
    "& div": {
      [theme.breakpoints.down("sm")]: {
        width: "fit-content",
        margin: "auto",
      },
    },
    "& .SocialLinks": {
      marginTop: "11vw",
      [theme.breakpoints.down("sm")]: {
        marginTop: "22vw",
        textAlign: "center",
      },
    },
    "& h1": {
      textAlign: "left",
      fontFamily: "Muli-Black",
      fontSize: "28px",
      lineHeight: "24px",
      background: "#ffffffe6",
      display: "block",
      width: "fit-content",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
      },
    },
  },
}));

function Main({ element, sticky, handleCurrentSection }) {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const classes = useStyles();
  useMenuOnScroll(handleCurrentSection, element.current);

  const toggleHover = () => {
    setHover(!hover);
  };

  return (
    <div
      ref={element}
      name="HOME"
      className={classes.MainContentAlignment}
      style={sticky ? { paddingTop: "76px" } : {}}
    >
      {/* <img style={{width: "50vh",height: "100vh",position: "absolute",left: 0,top: 0,zIndex: 1,}} src={LeftBackground} alt=""></img> */}

      <div className={classes.MainCoverLeft}>
        <div>
          <h1
            style={{
              /*textAlign:"left",fontFamily: "Muli-Black",fontSize: 28,lineHeight: "24px",*/ margin:
                "0 0 20px",
            }}
          >
            {" "}
            {t("Main.1")}{" "}
          </h1>
          <h1
            style={{
              /*textAlign:"left",fontFamily: "Muli-Black",fontSize: 28,lineHeight: "24px",*/ margin:
                "0 0 28px" /*,color: "#EC4847",margin: 0,*/,
            }}
          >
            {" "}
            {t("Main.2")}{" "}
          </h1>
          <h2
            style={{
              fontFamily: "Muli-Black",
              textTransform: "uppercase",
              lineHeight: "16px",
              marginBottom: "16px" /*,color: "#403939",marginTop: "2vh",*/,
            }}
          >
            {" "}
            {t("Main.3")}{" "}
          </h2>
          <p
            style={{
              fontFamily: "Muli-Regular",
              fontSize: 14,
              marginBottom: "28px",
              lineHeight: "12px" /*,color: "#403939",marginTop: "1vh",*/,
            }}
          >
            {" "}
            {t("Main.4")}{" "}
          </p>

          {/* style={{backgroundColor: "#EC4847",fontFamily: "Muli-Bold",fontSize: 16,padding: "1vh",color: "white",marginTop: "2vh",borderColor: "transparent", borderRadius: 6,width: "10vw",opacity: hover ? 0.5 : 1,}} */}

          <Link
            to="contact-section"
            spy={true}
            smooth={true}
            duration={500}
            className="some-class"
            activeClass="some-active-class"
          >
            <button
              className="BtnLaborBig"
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
            >
              {t("Main.5")}
            </button>
          </Link>

          <div className="SocialLinks">
            <a
              className="Instagram"
              href="https://www.instagram.com"
              target="_blank"
            >
              Instagram
            </a>
            <a
              className="Facebook"
              href="https://www.facebook.com/"
              target="_blank"
            >
              Facebook
            </a>
            <a
              className="Linkedin"
              href="https://www.Linkedin.com/"
              target="_blank"
            >
              Linkedin
            </a>
          </div>
        </div>
      </div>
      {/* <div style={{ flex: 1 }}> */}
      {/* #ea3434bf */}
      <Hidden smDown>
        {/* <GuyGazingMemo style={{position: "absolute",right: 0,height: "100%",}}></GuyGazingMemo> */}
        <GuyGazing2Memo
          style={{
            position: "absolute",
            right: "-4vw",
            height: "100%",
            top: "1vw",
            width: "68vw",
            zIndex: -1,
          }}
        ></GuyGazing2Memo>
      </Hidden>
      {/* </div> */}
    </div>
  );
}

export default Main;
