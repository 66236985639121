import { memo } from "react";
import { ReactComponent as GuyGazing } from "./GuyGazing.svg";
import { ReactComponent as GuyGazing2 } from "./GuyGazingUniv2-0-01.svg";
import { ReactComponent as LeftBackground } from "./LeftBackground.svg";
import { ReactComponent as Devices } from "./Devices.svg";
import { ReactComponent as TabletWithGirl } from "./TabletWithGirl.svg";
import { ReactComponent as SixMonitorsGuy } from "./SixMonitorsGuy.svg";
import { ReactComponent as IpeLogo } from "./LogoIpe.svg";
import { ReactComponent as FingLogo } from "./FingLogo.svg";
import { ReactComponent as PalacioHotel } from "./PalacioHotel.svg";
import { ReactComponent as ShillBidder } from "./ShillBidder.svg";
import { ReactComponent as Acrimax } from "./Acrimax.svg";
import { ReactComponent as Capter } from "./LogoCater.svg";
import { ReactComponent as companyGirl } from "./companyGirlBack.svg";
import { ReactComponent as iot } from "./iott-01.svg";
import { ReactComponent as mobile } from "./mobile-01.svg";
import { ReactComponent as industry } from "./industry-01.svg";
import { ReactComponent as Hardware } from "./Hardware.svg";
import { ReactComponent as ProductDisc } from "./ProductDisc.svg";
import { ReactComponent as leftPROJECTSBackgroundSpot } from "./LeftPROJECTSBackgroundSpot.svg";

export const LeftBackgroundMemo = memo(LeftBackground);
export const HardwareMemo = memo(Hardware);
export const iotMemo = memo(iot);
export const industryMemo = memo(industry);
export const mobileMemo = memo(mobile);
export const ProductDiscMemo = memo(ProductDisc);
export const GuyGazingMemo = memo(GuyGazing);
export const GuyGazing2Memo = memo(GuyGazing2);
export const DevicesMemo = memo(Devices);
export const TabletWithGirlMemo = memo(TabletWithGirl);
export const SixMonitorsGuyMemo = memo(SixMonitorsGuy);
export const IpeLogoMemo = memo(IpeLogo);
export const FingLogoMemo = memo(FingLogo);
export const PalacioHotelMemo = memo(PalacioHotel);
export const ShillBidderMemo = memo(ShillBidder);
export const AcrimaxMemo = memo(Acrimax);
export const CapterMemo = memo(Capter);
export const CompanyGirl = memo(companyGirl);
export const LeftPROJECTSBackgroundSpot = memo(leftPROJECTSBackgroundSpot);
