import { memo } from "react";

import { ReactComponent as LaborFooter } from "./LaborFooter.svg";
import { ReactComponent as InstagramLogo } from "./InstagramLogo.svg";
import { ReactComponent as FaceBookLogo } from "./FaceBookLogo.svg";
import { ReactComponent as LinkedInLogo } from "./LinkedInLogo.svg";

export const LaborFooterMemo = memo(LaborFooter);
export const LinkedInLogoMemo = memo(LinkedInLogo);
export const FaceBookLogoMemo = memo(FaceBookLogo);
export const InstagramLogoMemo = memo(InstagramLogo);
