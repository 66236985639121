import React, { useState } from "react";
import { Main, Services, Company, Contact } from "../Sections";
import Customers from "../Sections/Customers";
import Footer from "../Footer/Footer";
import Projects from "../Projects/Projects";
import NavBar from "../NavBar/NavBar";
import { Element } from "react-scroll";
import useSticky from "../../hooks/useSticky.js";

function Home() {
  const { isSticky, element } = useSticky();
  const [currentScrollSection, setCurrentScrollSection] = useState("HOME");

  function handleCurrentSection(section) {
    if (section !== "" && section !== currentScrollSection)
      setCurrentScrollSection(section);
  }

  return (
    <div>
      <div style={{overflow: 'hidden'}}>
        <div className="AppPadding">
          <NavBar
            sticky={isSticky}
            currentScrollSection={currentScrollSection}
          ></NavBar>
        </div>

        <Element id="main-section" name="main-section">
          <div className="AppPadding">
            <Main
              element={element}
              sticky={isSticky}
              handleCurrentSection={handleCurrentSection}
            ></Main>
          </div>
        </Element>
        <Element id="services-section" name="services-section">
          <div className="AppPadding">
            <Services handleCurrentSection={handleCurrentSection}></Services>
          </div>
        </Element>
        <Element id="projects-section" name="projects-section">
          <div className="AppPadding">
            <Projects handleCurrentSection={handleCurrentSection}></Projects>
          </div>
        </Element>
        <Element id="company-section" name="company-section">
          <div className="AppPadding">
            <Company handleCurrentSection={handleCurrentSection}></Company>
          </div>
        </Element>
        <Element id="customers-section" name="customers-section">
          <div className="AppPadding" style={{ background: "#39374d13" }}>
            <Customers></Customers>
          </div>
        </Element>
        <Element id="contact-section" name="contact-section">
          <div className="AppPadding">
            <Contact handleCurrentSection={handleCurrentSection}></Contact>
          </div>
        </Element>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Home;
