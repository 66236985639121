import { useEffect, useState, useRef } from "react";

function useMenuOnScroll(handleCurrentSection, element) {

  const handleScroll = () => {
    if(element !== null){
      if (window.scrollY > element.offsetTop - 150 && window.scrollY < element.offsetTop + element.clientHeight - 75 * 2)
        handleCurrentSection(element.getAttribute('name'));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", () => handleScroll);
  });
}

export default useMenuOnScroll;
