import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {IpeLogoMemo,ShillBidderMemo,PalacioHotelMemo,AcrimaxMemo,CapterMemo} from "./memoized-svgs";

//Styles by breakpoint
const useStyles = makeStyles((theme) => ({
    CustomersSectionMargin: {
      [theme.breakpoints.up('xs')]: {
        margin: "64px -10vw",
        padding: '40px 0'
      },
      [theme.breakpoints.up('md')]: {
        margin: "180px 25px 0px",
        padding: '56px 0', 
      }
    }
  }));

const Customers = () => {
  const classes = useStyles();  

    return (
        <div className={classes.CustomersSectionMargin}>
            {/* <h2>Confiaron en nosotros</h2> */}
            <ul className="CustomersLogoList">
                <li><IpeLogoMemo/></li>
                <li><PalacioHotelMemo/></li>
                <li><AcrimaxMemo style={{borderRadius:"100px"}} /></li>
                <li><ShillBidderMemo/></li>
                <li><CapterMemo/></li>
            </ul>
        </div>
    )
}

export default Customers;