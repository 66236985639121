import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  project: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "64vw",
    height: "32vw",
    display: "flex",
    borderRadius: "6px",
    overflow: "hidden",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      height: "88vh",
      width: "88vw",
      display: "block",
      overflowY: "auto",
    },
  },
  projectImgSection: {
    width: "50%",
    height: "100%",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      height: "44vh",
      width: "88vw",
    },
  },
  projectTextSection: {
    [theme.breakpoints.down("sm")]: {
      height: "calc(44vh - 8vw)",
      width: "calc(100% - 8vw)",
    },
    width: "calc(50% - 8vw)",
    height: "calc(100% - 8vw)",
    padding: "4vw",
    "& h1": {
      margin: "0",
      fontSize: "28px",
      textAlign: "left",
    },
    "& h2": {
      margin: "8px 0 20px",
      fontSize: "16px",
      textAlign: "left",
    },
    "& p": {
      margin: "8px 0 20px",
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "justify",
    },
  },
  projectType: {
    [theme.breakpoints.down("sm")]: {
      padding: "2vh 0",
    },
    background: "rgb(236, 72, 71, 0.8)",
    padding: "36px 0",
    width: "100%",
    textAlign: "center",
    "& h2": {
      margin: "0",
      color: "#fff",
      fontSize: " 20px",
    },
  },
  closeIconWrapper: {
    top: "1.1vw",
    right: "1.2vw",
    position: "absolute",
    color: "#92919D",
    display: "flex",
    "& *:hover": {
      color: "#39374D",
    },
    [theme.breakpoints.down("sm")]: {
      background: "rgb(236, 72, 71, 0.8)",
      color: "#fff",
      borderRadius: "8px",
    },
  },
  textContainer: {
    overflowY: "auto",
    height: "-moz-available",
    height: "-webkit-fill-available",
  },
}));

export default function ProjectDetail({
  open,
  handleModalOpenClose,
  projectInfo,
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModalOpenClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.project}>
            <a
              className={classes.closeIconWrapper}
              onClick={handleModalOpenClose}
            >
              <CloseIcon />
            </a>
            <div
              className={classes.projectImgSection}
              style={
                projectInfo.title.toUpperCase().includes('AGRANEL') ?
                {
                  backgroundImage: "url(" + projectInfo.imgSource + ")",
                  display: "flex",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
              }
              :
                {
                  backgroundImage: "url(" + projectInfo.imgSource + ")",
                  display: "flex"
              }            
            }
            >
              <div className={classes.projectType}>
                <h2>{projectInfo.type}</h2>
              </div>
            </div>
            <div className={classes.projectTextSection}>
              <div className={classes.textContainer}>
                <h1 className="notranslate">{projectInfo.title}</h1>
                <h2>{projectInfo.subtitle}</h2>
                <p>
                  {projectInfo.contentText} <br /> {projectInfo.contentText1}
                  <br />
                  {projectInfo.contentText2 &&
                  projectInfo.contentText2.includes("https://") ? (
                    <a href={projectInfo.contentText2} target="_blank" className="link" style={{fontSize: "16px"}}>
                      {projectInfo.contentText2.replace("https://", "").replace("/","")}
                    </a>
                  ) : (
                    projectInfo.contentText2
                  )}
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
